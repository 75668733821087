<template>
  <section class="claim-profile h-100 p-3 pb-5">
    <div class="container">
      <div class="d-flex align-items-center justify-content-center h-100 mt-3 mb-3 pt-2 pb-2">
        <div class="d-flex align-items-center me-auto">
          <div class="flex-shrink-0">
            <img
              src="@/assets/images/logo.png"
              class="logo"
              alt="Relatus"
            >
          </div>
          <div class="flex-grow-1 ms-3">
            <h2>Welcome to Relatus!</h2>
          </div>
        </div>

        <router-link
          :to="{name: 'instructions'}"
        >
          <r-button
            variant="primary"
            outline
            round
            class="btn-secondary-outline"
          >
            Go Back
          </r-button>
        </router-link>
      </div>
    </div>

    <div class="container mb-5">
      <div class="d-flex align-items-center text-start">
        <div class="d-flex flex-column">
          <h3 class="text me-auto fw-light">
            It’s looks like we have a Relatus Profile ready for you to claim
          </h3>
          <p class="text align-self-center text-start">
            There are multiple profiles associated with your information. If none pertain to you, please select the button below this line
          </p>
        </div>
      </div>
    </div>

    <div class="container container-card h-100 p-3">
      <div class="row">
        <div class="col col-sm-4 text-start">
          <img
            src="@/assets/images/user_profile_1.png"
            class="profile-picture-grid"
            alt="Aaron Clarke"
          >
          <img
            src="@/assets/images/social_icons.png"
            class="social-icons mt-4"
          >
        </div>

        <div class="col-9 col-sm-8">
          <div class="d-flex align-items-center pb-2 mb-3 border-bottom-light-gray">
            <h3 class="text-orange">
              Your Relatus Profile
            </h3>
            <a class="btn btn-secondary-outline ms-auto">This is Me</a>
          </div>

          <p class="text-uppercase fw-bold">
            About Aaron
          </p>
          <p>I am the lead business development advisor with the Goldman Sachs Wealth Management Team, an advisory practice servicing the needs of ultra-high net worth families for many years, and focus on assets allocation, values-based wealth management planning and portfolio construction. I started my career in Real Estate Finance, focusing on finding lending solutions for real estate investors and home owners. Prior to my career in finance, I played professional basketball in Asia, Europe, South America, The Middle East, as well as here in the states. I earned my B.A. in Political Science with a minor in Business Administration from The...</p>

          <div class="d-flex text-start mt-4">
            <div class="mb-2">
              <span class="d-block text-gray me-2">Email Address</span> <span class="text-black fw-bold">aclarke@goldman.com</span>
            </div>

            <div class="mb-2 ms-5">
              <span class="d-block text-gray me-2">Phone Number</span> <span class="text-dark fw-bold">212-986-9573</span>
            </div>
          </div>

          <div class="d-flex text-start mt-4">
            <div class="mb-3">
              <span class="d-block text-gray me-2">Languages</span>
              <div><span class="text-black fw-bold">English</span> <span>(Preferred),</span></div>
              <div><span class="text-black fw-bold">Italian</span> <span>(Conversational)</span></div>
            </div>

            <div class="ms-5">
              <span class="d-block text-gray me-2">Skills</span> <span class="tag">Finance</span><span class="tag">Tax Law</span><span class="tag">Mentor</span><span class="tag">Asset Management</span><span class="tag">M & A</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col col-sm-4">
          <div class="text-start mt-5">
            <span class="d-block title text-uppercase pb-2 text-dark border-bottom-light-gray">What makes John relevant?</span>
            <span class="d-block subtitle text-uppercase mt-2 mb-4">Groups</span>

            <div class="d-flex">
              <div class="flex-shrink-1">
                <img
                  src="@/assets/images/university_oregon.png"
                  class="company-logo-side"
                  alt="Company"
                >
              </div>

              <div class="flex-grow-1 ms-2">
                <span class="d-block text-black fw-bold">University of Oregon</span>
                <span class="text-black">Eugene, OR</span>
              </div>
            </div>

            <div class="d-flex mt-4">
              <div class="flex-shrink-1">
                <img
                  src="@/assets/images/next_invest_wealth.png"
                  class="company-logo-side"
                  alt="Company"
                >
              </div>

              <div class="flex-grow-1 ms-2">
                <span class="d-block text-black fw-bold">Next Invest Wealth Management Group</span>
                <span class="text-black">New York, NY</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-9 col-sm-8">
          <div class="container-experiences">
            <span class="d-block title text-uppercase pb-2 text-dark border-bottom-light-gray">Past Experience</span>
            <span class="d-block subtitle text-uppercase mt-2 mb-4">Companies</span>

            <div class="past-experience">
              <img
                src="@/assets/images/company_1.png"
                class="company-logo d-block"
                alt="Company"
              >
              <img
                src="@/assets/images/gray_bullet.svg"
                class="bullet"
              >
              <span class="text-gray fw-bold">2020</span>
            </div>

            <div class="past-experience">
              <img
                src="@/assets/images/company_2.png"
                class="company-logo d-block"
                alt="Company"
              >
              <img
                src="@/assets/images/gray_bullet.svg"
                class="bullet"
              >
              <span class="text-gray fw-bold">2018</span>
            </div>

            <div class="past-experience">
              <img
                src="@/assets/images/company_3.png"
                class="company-logo d-block"
                alt="Company"
              >
              <img
                src="@/assets/images/gray_bullet.svg"
                class="bullet"
              >
              <span class="text-gray fw-bold">2013</span>
            </div>

            <div class="past-experience">
              <img
                src="@/assets/images/company_4.png"
                class="company-logo d-block"
                alt="Company"
              >
              <img
                src="@/assets/images/gray_bullet.svg"
                class="bullet"
              >
              <span class="text-gray fw-bold">2012</span>
            </div>

            <span class="line" />
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-5 mb-4">
      <div class="d-flex align-items-center text-start">
        <div class="d-flex flex-column">
          <h6 class="text me-auto fw-light">
            Below are some additional profiles that contain the information you provided
          </h6>
          <p class="text text-start">
            Please select all the profiles that are associated to you
          </p>
        </div>
      </div>
    </div>

    <div class="container container-card h-100 p-3 mb-5">
      <div class="row">
        <div class="col col-sm-4 text-start">
          <img
            src="@/assets/images/user_profile_2.png"
            class="profile-picture-grid"
            alt="Aaron Clarke"
          >
        </div>

        <div class="col-9 col-sm-8">
          <div class="d-flex align-items-center pb-2 mb-3 border-bottom-light-gray">
            <span class="text-uppercase fw-bold">About Aaron</span>
            <a class="btn btn-secondary-outline ms-auto">This is Me</a>
          </div>

          <p>I am the lead business development advisor with the Goldman Sachs Wealth Management Team, an advisory practice servicing the needs of ultra-high net worth families for many years, and focus on assets allocation, values-based wealth management planning and portfolio construction. I started my career in Real Estate Finance, focusing on finding lending solutions for real estate investors and home owners. Prior to my career in finance, I played professional basketball in Asia, Europe, South America, The Middle East, as well as here in the states. I earned my B.A. in Political Science with a minor in Business Administration from The...</p>

          <div class="container-experiences">
            <span class="d-block title text-uppercase pb-2 text-dark border-bottom-light-gray">Past Experience</span>
            <span class="d-block subtitle text-uppercase mt-2 mb-4">Companies</span>

            <div class="past-experience">
              <img
                src="@/assets/images/company_1.png"
                class="company-logo d-block"
                alt="Company"
              >
              <img
                src="@/assets/images/gray_bullet.svg"
                class="bullet"
              >
              <span class="text-gray fw-bold">2020</span>
            </div>

            <div class="past-experience">
              <img
                src="@/assets/images/company_2.png"
                class="company-logo d-block"
                alt="Company"
              >
              <img
                src="@/assets/images/gray_bullet.svg"
                class="bullet"
              >
              <span class="text-gray fw-bold">2018</span>
            </div>

            <div class="past-experience">
              <img
                src="@/assets/images/company_3.png"
                class="company-logo d-block"
                alt="Company"
              >
              <img
                src="@/assets/images/gray_bullet.svg"
                class="bullet"
              >
              <span class="text-gray fw-bold">2013</span>
            </div>

            <div class="past-experience">
              <img
                src="@/assets/images/company_4.png"
                class="company-logo d-block"
                alt="Company"
              >
              <img
                src="@/assets/images/gray_bullet.svg"
                class="bullet"
              >
              <span class="text-gray fw-bold">2012</span>
            </div>

            <span class="line" />
          </div>
        </div>
      </div>
    </div>


    <div class="container container-card h-100 p-3 mb-5">
      <div class="row">
        <div class="col col-sm-4 text-start">
          <img
            src="@/assets/images/user_profile_3.png"
            class="profile-picture-grid"
            alt="Aaron Clarke"
          >
        </div>

        <div class="col-9 col-sm-8">
          <div class="d-flex align-items-center pb-2 mb-3 border-bottom-light-gray">
            <span class="text-uppercase fw-bold">About Aaron</span>
            <a class="btn btn-secondary-outline ms-auto">This is Me</a>
          </div>

          <p>I am an Fund Manager at Saber Funds.</p>
          <p>ANALYST / PORTFOLIO MANAGER</p>
          <p>Results-generating Investment Manager who has produced superior performance in up and down markets. Demonstrates a broad-based experience and a deep knowledge of the industrial and Mining sectors. Summa Cum Laude Wharton graduate with a triple..</p>

          <div class="container-experiences">
            <span class="d-block title text-uppercase pb-2 text-dark border-bottom-light-gray">Past Experience</span>
            <span class="d-block subtitle text-uppercase mt-2 mb-4">Companies</span>

            <div class="past-experience">
              <img
                src="@/assets/images/company_5.png"
                class="company-logo d-block"
                alt="Company"
              >
              <img
                src="@/assets/images/gray_bullet.svg"
                class="bullet"
              >
              <span class="text-gray fw-bold">2020</span>
            </div>

            <div class="past-experience">
              <img
                src="@/assets/images/company_6.png"
                class="company-logo d-block"
                alt="Company"
              >
              <img
                src="@/assets/images/gray_bullet.svg"
                class="bullet"
              >
              <span class="text-gray fw-bold">2017</span>
            </div>

            <div class="past-experience">
              <img
                src="@/assets/images/company_7.png"
                class="company-logo d-block"
                alt="Company"
              >
              <img
                src="@/assets/images/gray_bullet.svg"
                class="bullet"
              >
              <span class="text-gray fw-bold">2014</span>
            </div>

            <div class="past-experience">
              <img
                src="@/assets/images/company_8.png"
                class="company-logo d-block"
                alt="Company"
              >
              <img
                src="@/assets/images/gray_bullet.svg"
                class="bullet"
              >
              <span class="text-gray fw-bold">2010</span>
            </div>

            <span class="line" />
          </div>
        </div>
      </div>
    </div>

    <div class="container container-card h-100 p-3 mb-5">
      <div class="row">
        <div class="col col-sm-4 text-start">
          <img
            src="@/assets/images/user_profile_4.png"
            class="profile-picture-grid"
            alt="Aaron Clarke"
          >
        </div>

        <div class="col-9 col-sm-8">
          <div class="d-flex align-items-center pb-2 mb-3 border-bottom-light-gray">
            <span class="text-uppercase fw-bold">About Aaron</span>
            <a class="btn btn-secondary-outline ms-auto">This is Me</a>
          </div>

          <p>I am the lead business development advisor with the Goldman Sachs Wealth Management Team, an advisory practice servicing the needs of ultra-high net worth families for many years, and focus on assets allocation, values-based wealth management planning and portfolio construction. Prior to my career in finance, I played professional basketball in Asia, Europe, South America, The Middle East, as well as here in the states.</p>

          <div class="container p-0">
            <div class="row">
              <div class="col">
                <div class="text-start mt-4">
                  <span class="d-block title text-uppercase pb-2 mb-2 text-dark border-bottom-light-gray">Intro</span>
                  <div class="mb-2">
                    <span class="text-gray">Studied @</span> <span class="text-blue">University of Oregon</span>
                  </div>

                  <div class="mb-2">
                    <span class="text-gray">Went to</span> <span class="text-blue">Newport Harbor High School</span>
                  </div>

                  <div class="mb-3">
                    <span class="text-gray">Lives in</span> <span class="text-blue">New York, NY</span>
                  </div>

                  <div class="mb-3">
                    <span class="text-gray">From</span> <span class="text-blue">Newport Beach, California</span>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="text-start mt-4">
                  <span class="d-block title text-uppercase pb-2 mb-2 text-dark border-bottom-light-gray">Hobbies</span>
                  <div class="mb-2">
                    <span class="text-gray me-2">Basketball</span>
                  </div>

                  <div class="mb-2">
                    <span class="text-gray me-2">Rock Climbing</span>
                  </div>

                  <div class="mb-3">
                    <span class="text-gray me-2">Architecture</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container container-card h-100 p-3 mb-5">
      <div class="container">
        <div class="row">
          <div class="col col-sm-4">
            <img
              src="@/assets/images/user_profile_5.png"
              class="profile-picture-grid"
              alt="Aaron Clarke"
            >
          </div>
          <div class="col-9 col-sm-8">
            <div class="d-flex align-items-center pb-2 mb-3 border-bottom-light-gray">
              <span class="text-uppercase fw-bold">Recent Posts</span>
              <a class="btn btn-secondary-outline ms-auto">This is Me</a>
            </div>

            <div class="d-flex">
              <div class="flex-shrink-0">
                <img
                  src="@/assets/images/twitter_example_1.jpg"
                  class="twitter-picture"
                >
              </div>
              <div class="flex-grow-1 ms-4">
                <p><span class="fw-bold">About Aaron</span> | <span class="text-blue">@clarkeaaron</span></p>
                <p>What will the NYC recovery look like post-COVID? Will people move back into Manhattan? Was moving out of the city a quality of life increase move? So what now? Back to the bustle of the city ...</p>
              </div>
            </div>

            <div class="d-flex mt-3">
              <div class="flex-shrink-0">
                <img
                  src="@/assets/images/twitter_example_2.jpg"
                  class="twitter-picture"
                >
              </div>
              <div class="flex-grow-1 ms-4">
                <p><span class="fw-bold">About Aaron</span> | <span class="text-blue">@clarkeaaron</span></p>
                <p>What will the NYC recovery look like post-COVID? Will people move back into Manhattan? Was moving out of the city a quality of life increase move? So what now? Back to the bustle of the city ...</p>
              </div>
            </div>

            <div class="d-flex mt-3">
              <div class="flex-shrink-0">
                <img
                  src="@/assets/images/twitter_example_3.jpg"
                  class="twitter-picture"
                >
              </div>
              <div class="flex-grow-1 ms-4">
                <p><span class="fw-bold">About Aaron</span> | <span class="text-blue">@clarkeaaron</span></p>
                <p>What will the NYC recovery look like post-COVID? Will people move back into Manhattan? Was moving out of the city a quality of life increase move? So what now? Back to the bustle of the city ...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container container-card h-100 p-3 mb-5">
      <div class="container">
        <div class="row">
          <div class="col col-sm-4">
            <img
              src="@/assets/images/user_profile_6.png"
              class="profile-picture-grid"
              alt="Aaron Clarke"
            >
          </div>
          <div class="col-9 col-sm-8">
            <div class="d-flex align-items-center pb-2 mb-3 border-bottom-light-gray">
              <span class="text-uppercase fw-bold">Gmail Account</span>
              <a class="btn btn-secondary-outline ms-auto">This is Me</a>
            </div>

            <div class="row g-3 align-items-center">
              <div class="col-auto">
                <label class="col-form-label">Account Name</label>
              </div>
              <div class="col-auto">
                <input
                  type="text"
                  class="form-control ms-1"
                  value="Aaron Frederick Clarke"
                >
              </div>
            </div>

            <div class="row g-3 align-items-center mt-2">
              <div class="col-auto">
                <label class="col-form-label">Email Address</label>
              </div>
              <div class="col-auto">
                <input
                  type="text"
                  class="form-control ms-2"
                  value="aclarke@goldman.com"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container p-0 mt-3">
      <div class="d-flex align-items-center justify-content-center h-100 pt-3 pb-3 mt-2">
        <a class="btn btn-secondary-outline me-auto">More Profiles</a>
        <a class="btn btn-secondary-outline me-4">Back to Top</a>
        <router-link
          :to="{name: 'setup.options'}"
          class="btn btn-secondary"
        >
          Claim Profiles
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ClaimProfile'
}
</script>

<style scoped src="@/css/bootstrap.min.css"></style>

<style scoped>
a {
  text-decoration: none!important;
}
.claim-profile {
  background-color: #F9F9F9;
}
.claim-profile h2 {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #000000;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.claim-profile h3 {
  font-size: 26px;
  line-height: 36px;
  color: #142328;
}
.claim-profile .container:first-child > .d-flex {
  border-bottom: 0.5px solid #332C54;
}
h3.text-orange {
  font-weight: 600;
}
h6 {
  font-size: 22px;
}
span.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #142328;
}
span.subtitle {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #999799;
}
input {
  border: 1px solid rgba(3, 29, 90, 0.2);
  box-sizing: border-box;
  border-radius: 35px;
  padding: 10px;
}
.container-card {
  background-color: #FFFFFF;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}
.logo {
  max-height: 33px;
}
.company-logo {
  max-height: 70px;
}
.company-logo-side {
  max-height: 45px;
}
.profile-picture {
  max-height: 300px;
}
.profile-picture-grid {
  width: 100%;
}
.twitter-picture {
  max-height: 100px;
}
.social-icons {
  max-height: 30px;
}
.tag {
  background: rgba(3, 29, 90, 0.2);
  border-radius: 3px;
  padding: 5px;
  margin-right: 5px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  color: #000000;
}
.container-card {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
}
.container-experiences {
   text-align: left;
   margin-top: 3rem!important;
   position: relative;
}
.container-experiences span.line {
  position: absolute;
  width: 300px;
  border-bottom: solid #C4C4C4 0.2em;
  bottom: 33px;
  left: 40px;
}
.past-experience {
  display: inline-block;
  position: relative;
  text-align: center;
  margin-right: 30px;
}
.past-experience .bullet {
  display: inline-block;
}
.past-experience span {
  display: block;
}
.btn-secondary {
  border-radius: 99px;
  background-color: rgb(131, 192, 59);
  border-color: rgb(131, 192, 59);
  color: #FFFFFF;
}
.btn-secondary-outline {
  border-radius: 99px;
  border-color: rgb(131, 192, 59);
  color: rgb(131, 192, 59);
}
</style>
